<template>
  <v-dialog v-model="offLineDialogComp" width="500" persistent>
    <v-card>
      <v-card-title class="text-h5 error white--text">
        <v-icon color="white" class="mr-2">mdi-alert</v-icon>
        <span>Error de conexión a Internet</span>
      </v-card-title>

      <v-card-text class="pt-2">
        <p>
          Hemos detectado que tu conexión a Internet no esté funcionando correctamente. Te
          recomendamos que verifiques tu conexión antes de continuar.
        </p>
        <p>
          Ten presente que si guardas el contenido sin conexión a Internet, puede ser que lo
          pierdas.
        </p>
        <p class="text-h6 text-center">
          Esta alerta se cerrará automáticamente en cuanto se recupere la conexión
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="$emit('handleOfflineDialog', false)">
          Entendido, cerrar
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean, default: false },
  },
  computed: {
    offLineDialogComp: {
      get() {
        return this.dialog;
      },
      set(v) {
        this.$emit("handleOfflineDialog", v);
      },
    },
  },
};
</script>

<style scoped></style>
